import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import { resolve } from '../../../helpers/urls';
import { childrenType } from '../../../types/children';

const SITE_ORIGIN = process.env.GATSBY_SITE_ORIGIN;

export const query = graphql`
  query MetadataQuery {
    site {
      siteMetadata {
        titleTemplate
        name
        image
        type
        siteUrl
        twitterhandle
      }
    }
  }
`;

export const Metadata = ({
  children,
  title,
  description,
  bodyclass,
}) => {
  const { site: { siteMetadata } } = useStaticQuery(query);
  const { pathname } = useLocation();
  const index = resolve('index');

  return (
    <>
      <Helmet
        title={title}
        titleTemplate={siteMetadata.titleTemplate}
        htmlAttributes={{ lang: 'en' }}
        bodyAttributes={{
          class: bodyclass,
        }}
      >
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
        <meta name="theme-color" content="#ffffff" />

        {/* SEO */}
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta name="siteinfo" content={`${SITE_ORIGIN}/robots.txt`} />
        <link href={`${SITE_ORIGIN}${index}`} rel="home" />
        <link rel="canonical" href={`${siteMetadata.siteUrl}${pathname}`} />
        <meta name="image" content={siteMetadata.image} />

        {/* Open Graph */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`${siteMetadata.siteUrl}${pathname}`} />
        <meta property="og:type" content={siteMetadata.type} />
        <meta property="og:image" content={siteMetadata.image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en" />
        <meta property="og:site_name" content={siteMetadata.name} />

        {/* Twitter */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={siteMetadata.twitterhandle} />
        <meta name="twitter:image" content={siteMetadata.image} />

        {/* Icons */}
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />

        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />

        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-57x57.png" sizes="57x57" />
        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-60x60.png" sizes="60x60" />
        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-72x72.png" sizes="72x72" />
        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-76x76.png" sizes="76x76" />
        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-114x114.png" sizes="114x114" />
        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-120x120.png" sizes="120x120" />
        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-144x144.png" sizes="144x144" />
        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-152x152.png" sizes="152x152" />
        <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-180x180.png" sizes="180x180" />

        <meta name="application-name" content={siteMetadata.name} />
        <meta name="msapplication-starturl" content={`${SITE_ORIGIN}?utm_source=msapplication_starturl`} />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="/img/meta/mstile.png" />
        <meta name="msapplication-square70x70logo" content="/img/meta/mstile-70x70.png" />
        <meta name="msapplication-square150x150logo" content="/img/meta/mstile-150x150.png" />
        <meta name="msapplication-wide310x150logo" content="/img/meta/mstile-310x150.png" />
        <meta name="msapplication-square310x310logo" content="/img/meta/mstile-310x310.png" />
      </Helmet>
      {children}
    </>
  );
};

Metadata.propTypes = {
  children: childrenType.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  bodyclass: string,
};

Metadata.defaultProps = {
  bodyclass: undefined,
};
